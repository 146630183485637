.rotatingBackground {
	background: linear-gradient(300deg, #f4db4e, #f77781, #ff8e53);
	background: linear-gradient(106deg, #6b9cfe 30%, #ff539b 90%);
	background-size: 180% 180%;
	animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
