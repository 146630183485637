.Flag {
	width: 21px;
	height: 15px;
	-o-object-fit: none;
	object-fit: none;

	-o-object-position: -1000px -1000px;
	object-position: -1000px -1000px;
}

.Flag--countryAD {
	-o-object-position: -10px -10px;
	object-position: -10px -10px;
}

.Flag--countryAE {
	-o-object-position: -41px -10px;
	object-position: -41px -10px;
}

.Flag--countryAG {
	-o-object-position: -72px -10px;
	object-position: -72px -10px;
}

.Flag--countryAL {
	-o-object-position: -103px -10px;
	object-position: -103px -10px;
}

.Flag--countryAM {
	-o-object-position: -134px -10px;
	object-position: -134px -10px;
}

.Flag--countryAO {
	-o-object-position: -165px -10px;
	object-position: -165px -10px;
}

.Flag--countryAR {
	-o-object-position: -196px -10px;
	object-position: -196px -10px;
}

.Flag--countryAT {
	-o-object-position: -227px -10px;
	object-position: -227px -10px;
}

.Flag--countryAU {
	-o-object-position: -258px -10px;
	object-position: -258px -10px;
}

.Flag--countryAZ {
	-o-object-position: -289px -10px;
	object-position: -289px -10px;
}

.Flag--countryBA {
	-o-object-position: -320px -10px;
	object-position: -320px -10px;
}

.Flag--countryBD {
	-o-object-position: -351px -10px;
	object-position: -351px -10px;
}

.Flag--countryBE {
	-o-object-position: -382px -10px;
	object-position: -382px -10px;
}

.Flag--countryBF {
	-o-object-position: -413px -10px;
	object-position: -413px -10px;
}

.Flag--countryBG {
	-o-object-position: -444px -10px;
	object-position: -444px -10px;
}

.Flag--countryBH {
	-o-object-position: -475px -10px;
	object-position: -475px -10px;
}

.Flag--countryBJ {
	-o-object-position: -506px -10px;
	object-position: -506px -10px;
}

.Flag--countryBN {
	-o-object-position: -10px -35px;
	object-position: -10px -35px;
}

.Flag--countryBO {
	-o-object-position: -41px -35px;
	object-position: -41px -35px;
}

.Flag--countryBR {
	-o-object-position: -72px -35px;
	object-position: -72px -35px;
}

.Flag--countryBS {
	-o-object-position: -103px -35px;
	object-position: -103px -35px;
}

.Flag--countryBT {
	-o-object-position: -134px -35px;
	object-position: -134px -35px;
}

.Flag--countryBW {
	-o-object-position: -165px -35px;
	object-position: -165px -35px;
}

.Flag--countryCA {
	-o-object-position: -196px -35px;
	object-position: -196px -35px;
}

.Flag--countryCD {
	-o-object-position: -227px -35px;
	object-position: -227px -35px;
}

.Flag--countryCG {
	-o-object-position: -258px -35px;
	object-position: -258px -35px;
}

.Flag--countryCH {
	-o-object-position: -289px -35px;
	object-position: -289px -35px;
}

.Flag--countryCI {
	-o-object-position: -320px -35px;
	object-position: -320px -35px;
}

.Flag--countryCL {
	-o-object-position: -351px -35px;
	object-position: -351px -35px;
}

.Flag--countryCM {
	-o-object-position: -382px -35px;
	object-position: -382px -35px;
}

.Flag--countryCN {
	-o-object-position: -413px -35px;
	object-position: -413px -35px;
}

.Flag--countryCO {
	-o-object-position: -444px -35px;
	object-position: -444px -35px;
}

.Flag--countryCR {
	-o-object-position: -475px -35px;
	object-position: -475px -35px;
}

.Flag--countryCY {
	-o-object-position: -506px -35px;
	object-position: -506px -35px;
}

.Flag--countryCZ {
	-o-object-position: -10px -60px;
	object-position: -10px -60px;
}

.Flag--countryDE {
	-o-object-position: -41px -60px;
	object-position: -41px -60px;
}

.Flag--countryDJ {
	-o-object-position: -72px -60px;
	object-position: -72px -60px;
}

.Flag--countryDK {
	-o-object-position: -103px -60px;
	object-position: -103px -60px;
}

.Flag--countryDO {
	-o-object-position: -134px -60px;
	object-position: -134px -60px;
}

.Flag--countryDZ {
	-o-object-position: -165px -60px;
	object-position: -165px -60px;
}

.Flag--countryEC {
	-o-object-position: -196px -60px;
	object-position: -196px -60px;
}

.Flag--countryEE {
	-o-object-position: -227px -60px;
	object-position: -227px -60px;
}

.Flag--countryEG {
	-o-object-position: -258px -60px;
	object-position: -258px -60px;
}

.Flag--countryES {
	-o-object-position: -289px -60px;
	object-position: -289px -60px;
}

.Flag--countryET {
	-o-object-position: -320px -60px;
	object-position: -320px -60px;
}

.Flag--countryEU {
	-o-object-position: -351px -60px;
	object-position: -351px -60px;
}

.Flag--countryFI {
	-o-object-position: -382px -60px;
	object-position: -382px -60px;
}

.Flag--countryFO {
	-o-object-position: -413px -60px;
	object-position: -413px -60px;
}

.Flag--countryFR {
	-o-object-position: -444px -60px;
	object-position: -444px -60px;
}

.Flag--countryGA {
	-o-object-position: -475px -60px;
	object-position: -475px -60px;
}

.Flag--countryGB {
	-o-object-position: -506px -60px;
	object-position: -506px -60px;
}

.Flag--countryGH {
	-o-object-position: -10px -85px;
	object-position: -10px -85px;
}

.Flag--countryGI {
	-o-object-position: -41px -85px;
	object-position: -41px -85px;
}

.Flag--countryGL {
	-o-object-position: -72px -85px;
	object-position: -72px -85px;
}

.Flag--countryGM {
	-o-object-position: -103px -85px;
	object-position: -103px -85px;
}

.Flag--countryGR {
	-o-object-position: -134px -85px;
	object-position: -134px -85px;
}

.Flag--countryGT {
	-o-object-position: -165px -85px;
	object-position: -165px -85px;
}

.Flag--countryGU {
	-o-object-position: -196px -85px;
	object-position: -196px -85px;
}

.Flag--countryGY {
	-o-object-position: -227px -85px;
	object-position: -227px -85px;
}

.Flag--countryHK {
	-o-object-position: -258px -85px;
	object-position: -258px -85px;
}

.Flag--countryHN {
	-o-object-position: -289px -85px;
	object-position: -289px -85px;
}

.Flag--countryHR {
	-o-object-position: -320px -85px;
	object-position: -320px -85px;
}

.Flag--countryHT {
	-o-object-position: -351px -85px;
	object-position: -351px -85px;
}

.Flag--countryHU {
	-o-object-position: -382px -85px;
	object-position: -382px -85px;
}

.Flag--countryID {
	-o-object-position: -413px -85px;
	object-position: -413px -85px;
}

.Flag--countryIE {
	-o-object-position: -444px -85px;
	object-position: -444px -85px;
}

.Flag--countryIL {
	-o-object-position: -475px -85px;
	object-position: -475px -85px;
}

.Flag--countryIM {
	-o-object-position: -506px -85px;
	object-position: -506px -85px;
}

.Flag--countryIN {
	-o-object-position: -10px -110px;
	object-position: -10px -110px;
}

.Flag--countryIQ {
	-o-object-position: -41px -110px;
	object-position: -41px -110px;
}

.Flag--countryIR {
	-o-object-position: -72px -110px;
	object-position: -72px -110px;
}

.Flag--countryIS {
	-o-object-position: -103px -110px;
	object-position: -103px -110px;
}

.Flag--countryIT {
	-o-object-position: -134px -110px;
	object-position: -134px -110px;
}

.Flag--countryJE {
	-o-object-position: -165px -110px;
	object-position: -165px -110px;
}

.Flag--countryJM {
	-o-object-position: -196px -110px;
	object-position: -196px -110px;
}

.Flag--countryJO {
	-o-object-position: -227px -110px;
	object-position: -227px -110px;
}

.Flag--countryJP {
	-o-object-position: -258px -110px;
	object-position: -258px -110px;
}

.Flag--countryKE {
	-o-object-position: -289px -110px;
	object-position: -289px -110px;
}

.Flag--countryKG {
	-o-object-position: -320px -110px;
	object-position: -320px -110px;
}

.Flag--countryKH {
	-o-object-position: -351px -110px;
	object-position: -351px -110px;
}

.Flag--countryKN {
	-o-object-position: -382px -110px;
	object-position: -382px -110px;
}

.Flag--countryKP {
	-o-object-position: -413px -110px;
	object-position: -413px -110px;
}

.Flag--countryKR {
	-o-object-position: -444px -110px;
	object-position: -444px -110px;
}

.Flag--countryKW {
	-o-object-position: -475px -110px;
	object-position: -475px -110px;
}

.Flag--countryKZ {
	-o-object-position: -506px -110px;
	object-position: -506px -110px;
}

.Flag--countryLA {
	-o-object-position: -10px -135px;
	object-position: -10px -135px;
}

.Flag--countryLB {
	-o-object-position: -41px -135px;
	object-position: -41px -135px;
}

.Flag--countryLC {
	-o-object-position: -72px -135px;
	object-position: -72px -135px;
}

.Flag--countryLI {
	-o-object-position: -103px -135px;
	object-position: -103px -135px;
}

.Flag--countryLK {
	-o-object-position: -134px -135px;
	object-position: -134px -135px;
}

.Flag--countryLS {
	-o-object-position: -165px -135px;
	object-position: -165px -135px;
}

.Flag--countryLT {
	-o-object-position: -196px -135px;
	object-position: -196px -135px;
}

.Flag--countryLU {
	-o-object-position: -227px -135px;
	object-position: -227px -135px;
}

.Flag--countryLV {
	-o-object-position: -258px -135px;
	object-position: -258px -135px;
}

.Flag--countryMA {
	-o-object-position: -289px -135px;
	object-position: -289px -135px;
}

.Flag--countryMC {
	-o-object-position: -320px -135px;
	object-position: -320px -135px;
}

.Flag--countryMD {
	-o-object-position: -351px -135px;
	object-position: -351px -135px;
}

.Flag--countryMG {
	-o-object-position: -382px -135px;
	object-position: -382px -135px;
}

.Flag--countryMK {
	-o-object-position: -413px -135px;
	object-position: -413px -135px;
}

.Flag--countryML {
	-o-object-position: -444px -135px;
	object-position: -444px -135px;
}

.Flag--countryMM {
	-o-object-position: -475px -135px;
	object-position: -475px -135px;
}

.Flag--countryMN {
	-o-object-position: -506px -135px;
	object-position: -506px -135px;
}

.Flag--countryMO {
	-o-object-position: -10px -160px;
	object-position: -10px -160px;
}

.Flag--countryMT {
	-o-object-position: -41px -160px;
	object-position: -41px -160px;
}

.Flag--countryMU {
	-o-object-position: -72px -160px;
	object-position: -72px -160px;
}

.Flag--countryMX {
	-o-object-position: -103px -160px;
	object-position: -103px -160px;
}

.Flag--countryMY {
	-o-object-position: -134px -160px;
	object-position: -134px -160px;
}

.Flag--countryMZ {
	-o-object-position: -165px -160px;
	object-position: -165px -160px;
}

.Flag--countryNA {
	-o-object-position: -196px -160px;
	object-position: -196px -160px;
}

.Flag--countryNE {
	-o-object-position: -227px -160px;
	object-position: -227px -160px;
}

.Flag--countryNG {
	-o-object-position: -258px -160px;
	object-position: -258px -160px;
}

.Flag--countryNI {
	-o-object-position: -289px -160px;
	object-position: -289px -160px;
}

.Flag--countryNL {
	-o-object-position: -320px -160px;
	object-position: -320px -160px;
}

.Flag--countryNO {
	-o-object-position: -351px -160px;
	object-position: -351px -160px;
}

.Flag--countryNZ {
	-o-object-position: -382px -160px;
	object-position: -382px -160px;
}

.Flag--countryOM {
	-o-object-position: -413px -160px;
	object-position: -413px -160px;
}

.Flag--countryPA {
	-o-object-position: -444px -160px;
	object-position: -444px -160px;
}

.Flag--countryPE {
	-o-object-position: -475px -160px;
	object-position: -475px -160px;
}

.Flag--countryPG {
	-o-object-position: -506px -160px;
	object-position: -506px -160px;
}

.Flag--countryPH {
	-o-object-position: -10px -185px;
	object-position: -10px -185px;
}

.Flag--countryPK {
	-o-object-position: -41px -185px;
	object-position: -41px -185px;
}

.Flag--countryPL {
	-o-object-position: -72px -185px;
	object-position: -72px -185px;
}

.Flag--countryPR {
	-o-object-position: -103px -185px;
	object-position: -103px -185px;
}

.Flag--countryPS {
	-o-object-position: -134px -185px;
	object-position: -134px -185px;
}

.Flag--countryPT {
	-o-object-position: -165px -185px;
	object-position: -165px -185px;
}

.Flag--countryPY {
	-o-object-position: -196px -185px;
	object-position: -196px -185px;
}

.Flag--countryQA {
	-o-object-position: -227px -185px;
	object-position: -227px -185px;
}

.Flag--countryREMOTE {
	-o-object-position: -258px -185px;
	object-position: -258px -185px;
}

.Flag--countryRO {
	-o-object-position: -289px -185px;
	object-position: -289px -185px;
}

.Flag--countryRS {
	-o-object-position: -320px -185px;
	object-position: -320px -185px;
}

.Flag--countryRU {
	-o-object-position: -351px -185px;
	object-position: -351px -185px;
}

.Flag--countryRW {
	-o-object-position: -382px -185px;
	object-position: -382px -185px;
}

.Flag--countrySA {
	-o-object-position: -413px -185px;
	object-position: -413px -185px;
}

.Flag--countrySE {
	-o-object-position: -444px -185px;
	object-position: -444px -185px;
}

.Flag--countrySG {
	-o-object-position: -475px -185px;
	object-position: -475px -185px;
}

.Flag--countrySI {
	-o-object-position: -506px -185px;
	object-position: -506px -185px;
}

.Flag--countrySK {
	-o-object-position: -10px -210px;
	object-position: -10px -210px;
}

.Flag--countrySL {
	-o-object-position: -41px -210px;
	object-position: -41px -210px;
}

.Flag--countrySM {
	-o-object-position: -72px -210px;
	object-position: -72px -210px;
}

.Flag--countrySN {
	-o-object-position: -103px -210px;
	object-position: -103px -210px;
}

.Flag--countrySO {
	-o-object-position: -134px -210px;
	object-position: -134px -210px;
}

.Flag--countrySV {
	-o-object-position: -165px -210px;
	object-position: -165px -210px;
}

.Flag--countryTD {
	-o-object-position: -196px -210px;
	object-position: -196px -210px;
}

.Flag--countryTH {
	-o-object-position: -227px -210px;
	object-position: -227px -210px;
}

.Flag--countryTJ {
	-o-object-position: -258px -210px;
	object-position: -258px -210px;
}

.Flag--countryTL {
	-o-object-position: -289px -210px;
	object-position: -289px -210px;
}

.Flag--countryTN {
	-o-object-position: -320px -210px;
	object-position: -320px -210px;
}

.Flag--countryTR {
	-o-object-position: -351px -210px;
	object-position: -351px -210px;
}

.Flag--countryTT {
	-o-object-position: -382px -210px;
	object-position: -382px -210px;
}

.Flag--countryTW {
	-o-object-position: -413px -210px;
	object-position: -413px -210px;
}

.Flag--countryTZ {
	-o-object-position: -444px -210px;
	object-position: -444px -210px;
}

.Flag--countryUA {
	-o-object-position: -475px -210px;
	object-position: -475px -210px;
}

.Flag--countryUG {
	-o-object-position: -506px -210px;
	object-position: -506px -210px;
}

.Flag--countryUS {
	-o-object-position: -10px -235px;
	object-position: -10px -235px;
}

.Flag--countryUY {
	-o-object-position: -41px -235px;
	object-position: -41px -235px;
}

.Flag--countryUZ {
	-o-object-position: -72px -235px;
	object-position: -72px -235px;
}

.Flag--countryVE {
	-o-object-position: -103px -235px;
	object-position: -103px -235px;
}

.Flag--countryVN {
	-o-object-position: -134px -235px;
	object-position: -134px -235px;
}

.Flag--countryXX {
	-o-object-position: -165px -235px;
	object-position: -165px -235px;
}

.Flag--countryYE {
	-o-object-position: -196px -235px;
	object-position: -196px -235px;
}

.Flag--countryZA {
	-o-object-position: -227px -235px;
	object-position: -227px -235px;
}
